<template>
	<layouts-store-minimum>
		<div class="wrapper">
			<div class="container bg-cover store-cover rounded border shadow position-relative p-3" :style="{'background-image': `url(${item.image_md})`}" v-if="!imageSkeletor && !!item.image_md">
				<a href="javascript:void(0);" class="btn btn-sm btn-neutral btn-image-show stretched-link " data-bs-toggle="modal" data-bs-target="#modal-image"><i class="bi bi-arrows-angle-expand"></i></a>
			</div>
			<Skeletor height="230" class="container bg-cover store-cover rounded" v-if="imageSkeletor" />
			<div id="item-description" class="sticky-top py-4">
				<div class="d-flex justify-content-between">
					<div>
						<h3 class="h3" v-show="loaded">{{ item.name }}</h3>
						<Skeletor width="200" class="mt-4 h3" v-show="!loaded" />
					</div>
					<div class="ms-3">
						<h3 class="h3 text-nowrap" v-show="loaded">{{ vueNumberFormat(priceReal, $personalizer.currency.value) }}</h3>
						<Skeletor width="100" class="mt-4 h3" v-show="!loaded" />
					</div>
				</div>
				<div class="d-flex align-items-center justify-content-end mt-2" v-if="loaded && hasDiscount">
					<small class="text-muted me-4" v-if="price != priceReal"><del>{{ vueNumberFormat(price, $personalizer.currency.value) }}</del></small>
					<button type="button" class="badge rounded-pill bg-danger text-end pulse" @click="modalDiscount">{{ discountDescription }} <i class="bi bi-info-circle-fill ms-1"></i></button>
				</div>
			</div>
			<p class="text-muted text-break text-white-space" v-show="loaded">{{ item.description }}</p>
			<Skeletor height="50" class="mt-2 text-muted" v-show="!loaded" />
			<div class="my-5" v-if="loaded">
				<catalog-group v-for="group in item.groups" v-bind:key="group.id" @update="update" :group="group" />
			</div>
			<div class="mt-3 mb-7" v-if="loaded">
				<label class="form-label">¿Tienes una aclaración sobre éste producto?</label>
				<input type="text" class="form-control" v-model="comments" placeholder="Agregar aquí tus comentarios" maxlength="100">
			</div>
		</div>

		<common-modal-image :image="item.image_md" v-if="!!item.image_md" />

		<div class="fixed-bottom">
			<div class="container bg-white py-4">
				<div class="row gx-5">
					<div class="offset-md-2 col-6 col-md-4">
						<div class="input-group input-group-sm">
							<button type="button" @click="decrease" class="btn btn-outline-secondary"><i class="bi bi-dash-lg"></i></button>
							<input type="text" v-model="quantity" class="form-control border-dark text-center fw-semibold" readonly>
							<button type="button" @click="increase" class="btn btn-outline-secondary"><i class="bi bi-plus-lg"></i></button>
						</div>
					</div>
					<div class="col-6 col-md-4">
						<div class="d-grid gap-2">
							<button class="btn btn-sm btn-dark" type="button" @click="addToCart">Agregar</button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<discount-show :discount="discount" v-if="hasDiscount" />
	</layouts-store-minimum>
</template>

<script>
	import { ref, computed, watch, watchEffect } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { useRoute } from 'vue-router';
	import { Modal } from 'bootstrap';
	import moment from 'moment';
	import _ from 'lodash';
	import composableRedirect from '@/composables/redirect';
	import composableCheckout from '@/composables/checkout';

	export default {
		setup() {
			const { redirectBack } = composableRedirect();
			const { addItem } = composableCheckout();
			const route = useRoute();
			const isDefined = computed(() => !!item.value.id);
			const hasStore = computed(() => store.getters['store/isDefined']);
			const imageSkeletor = ref(true);
			const selection = ref([]);
			const comments = ref(null);

			const loaded = computed(() => !!item.value.id);

			const item = computed(() => {
				var result = _.find(store.getters['store/catalog'].items, (row) => { return row.id === Number(route.params.id); });

				if(result) return result;
				else return { id: null, name: null, description: null, price: 0, image_md: null, groups: [] };
			});

			const unitPrice = computed(() => {
				var price = item.value.price;

				_.forEach(selection.value, (group) => {
					_.forEach(group.additionals, (additional) => {
						price+= additional.quantity * additional.unit_price;
					});
				});

				return price;
			});

			const price = computed(() => {
				return (unitPrice.value * quantity.value);
			});

			const catalogType = computed(() => (store.getters['code/isDefined']) ? store.getters['code/type'] : 'ONLINE');

			const discount = computed(() => {
				return _.find(store.getters['store/discounts'], (data) => {
					if(_.indexOf(data.catalogs, catalogType.value) === -1) return false;
					if(data.from && moment(data.from).isAfter(moment())) return false;
					if(data.to && moment(data.to).isBefore(moment())) return false;
					if(data.days && _.indexOf(data.days, moment().day()) === -1) return false;

					return (data.target == null || _.indexOf(data.target, item.value.id) != -1);
				});
			});
			const hasDiscount = computed(() => { return !!discount.value; });
			const discountAmount = computed(() => {
				if(!hasDiscount.value) return 0;

				var amount = 0;
				switch(discount.value.type) {
					case 'PERCENTAGE':
						amount = Math.round(price.value * (discount.value.value / 100) * 100) / 100;
						break;
					case 'PRODUCTS':
						var factor = Math.trunc(quantity.value / discount.value.products_from);
						var free = factor * (discount.value.products_from - discount.value.products_to);

						amount = free * unitPrice.value;
						break;
				}

				return amount;
			});
			const priceReal = computed(() => { return price.value - discountAmount.value; });

			const discountDescription = computed(() => {
				var description = null;

				if(hasDiscount.value) {
					switch(discount.value.type) {
						case 'PERCENTAGE':
							description = `${discount.value.value}% OFF`;
							break;
						case 'PRODUCTS':
							description = `Lleva ${discount.value.products_from} paga ${discount.value.products_to}`;
							break;
					}
				}

				return description;
			});

			const modalDiscount = () => {
				var modal = new Modal(document.getElementById('modal-discount'));
				modal.show();
			}

			const quantity = ref(1);
			const decrease = () => {
				if(quantity.value == 1) return;
				quantity.value-= 1;
			}
			const increase = () => quantity.value++;

			const update = (data) => {
				_.remove(selection.value, (row) => { return row.group_id == data.group_id; });
				selection.value.push(data);
			}

			const addToCart = () => {
				var added = addItem(item.value, quantity.value, selection.value, comments.value, true);
				if(added) router.push({ name: 'store.home', params: { slug: route.params.slug } });
			}

			watch(hasStore, (newValue) => {
				if(newValue && !isDefined.value) redirectBack();
			});

			watchEffect(() => {
				if(loaded.value) {
					imageSkeletor.value = !!item.value.image_md;

					if(item.value.image_md != null) {
						var loadImage = new Image();
						loadImage.onload = function() { imageSkeletor.value = false; };
						loadImage.src = item.value.image_md;
					}
				}
			});

			return { loaded, item, imageSkeletor, price, hasDiscount, discount, discountDescription, modalDiscount, priceReal, quantity, decrease, increase, update, comments, addToCart };
		}
	}
</script>

<style scoped>
	.container {
		max-width: 650px !important;
	}

	.store-cover {
		height: 230px;
		background-position: center;
	}

	.btn-image-show {
		padding: .25rem .5rem !important;
	}

	.btn-image-show i {
		font-size: 12px;
	}

	.wrapper {
		padding-bottom: 68px;
	}

	#item-description {
		background-color: var(--bs-body-bg) !important;
	}

	.badge {
		padding: .24rem .5rem;
	}

	.pulse {
		animation: animate 1s linear infinite
	}

	@keyframes animate {
		0%   { box-shadow: 0 0 0 0 rgba(255, 140, 169, 0.8); }
		100% { box-shadow: 0 0 0 8px rgba(255, 140, 169, 0); }
	}
</style>